<template>
  <div>
    <b-modal 
      id="UpdateSettingStatusModal" 
      scrollable 
      ok-disabled
      hide-header-close
      size="md"
    >
    <template #modal-header>
      <b-row>
        <b-col >
          <h5>
            対象エリア状況更新
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col >
          <b-form-checkbox
            id="checkbox-1"
            v-model="checkUserAllUpdate"
          >
            チェックの入ったエリアを一括変更
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>
    <table 
      class="table table-hover "
    >
      <tbody>
        <tr 
          v-for="(setting,statusIndex) in settingStatus"
          :key="statusIndex"
          :value="setting.id"
          style="cursor:pointer; " 
          @click="putSettingStatus(setting.id)"
        >
          <td 
            class="text-center"
          >
            {{ setting.value }}
          </td>
        </tr>
      </tbody>
    </table>
    <template #modal-footer>
      <b-row>
        <b-col 
          style="text-align:right;"
        >
          <b-button 
            @click="hideModal"
          >
            閉じる
          </b-button>
        </b-col>
      </b-row>
    </template>
    </b-modal>
  </div>
</template>

<script>
import { Apimixin } from '../mixins/api'
export default {
  mixins:[Apimixin],
  props:{
    userID:String,
    checkUpdate:[],
    areaUpdateType:String,
    updatePageType:String,
    prefectureCode:String,
    cityCode:String
  },
  data() {
    return {
      updateUserID:'',
      checkUserAllUpdate:false,
			updateSettingStatus:'',
			settingStatus:[
        {id:1,value:'ON'},
        {id:0,value:'OFF'}
      ],
    }
  },
  methods:{
    //モーダルクローズ
    hideModal() {
      this.$bvModal.hide('UpdateSettingStatusModal')
    },
    //エリア設定状況の更新
    putSettingStatus(statusID) {
      let estateVals = []
      if(this.checkUserAllUpdate && this.checkUpdate.length !== 0) {
        for(let updateData of this.checkUpdate) {
          estateVals.push({
            prefectureCd: updateData.prefectureCode,
            cityCd: updateData.cityCode,
            flag: statusID
          })
        }
      } else {
        estateVals.push({
          prefectureCd: this.prefectureCode,
          cityCd: this.cityCode,
          flag: statusID
        })
      }
      this.$swal({
        title: '処理中',
        html: '処理終了まで画面はそのままにしてください。', 
        allowOutsideClick : false , 
        showConfirmButton: false, 
      });
      this.putAreaListData(this.userID,estateVals,this.areaUpdateType)
    }
	}
}
</script>
