<template>
  <div>
    <Header/>
    <b-container
      style="padding-top:70px; max-width:98%"
    >
      <b-row>
        <b-col>
          <h3>
            担当エリア編集
          </h3>
        </b-col>
      </b-row>
      <b-row
        class="border mb-1"
        style="font-size:14px;"
      >
        <label
          class="col-lg-2 col-md-3 cols-4 col-sm-1 col-form-label w-auto"
          for="searchAddress"
        >
          エリア設定:
        </label>
        <b-col 
          md="1" 
          cols="8"
          sm="4"
        >
          <select 
            class="form-select w-auto"
            id="searchUser"
            v-model="search.settingStatus"
          >
            <option
              value='all'
            >
            </option>
            <option 
              v-for="(setting,propertyIndex) in settingStatus"
              :key="propertyIndex"
              :value="setting.id"
            >
              {{ setting.value }}
            </option>
          </select>
        </b-col>
        <label
          class="col-lg-2 col-md-3 cols-4 col-sm-1 col-form-label w-auto"
          for="searchAddress"
        >
          都道府県:
        </label>
        <b-col 
          md="2"
          cols="8"
          sm="4"
        >
          <select 
            class="form-select mx-auto"
            id="searchUser"
            v-model="search.address"
          >
            <option 
              value=""
            >
              全県
            </option>
            <option 
              v-for="(prefecture,prefectureIndex ) in prefectures"
              :key="prefectureIndex"
              :value="prefecture.id"
            >
              {{ prefecture.value }}
            </option>
            
          </select>
        </b-col>
        <label 
          class="col-lg-2 col-md-3 cols-2 col-form-label w-auto"
        >
          担当者:
        </label>
        <b-col
          lg="2"
          md="3"
          cols="8"
        >
          <select 
            class="form-select "
            id="searchUser"
            v-model="search.user"
          >
            <option 
              value=""
            >
              全ユーザー
            </option>
            <option 
              v-for="user in usersData" 
              :key="'user' + user.inUserID"
              :value="user.inUserID"
            >
              {{ user.vcUserLName }}
            </option>
          </select>
        </b-col>
        <b-col 
          md="3"
          cols="8"
        >
          <dl 
            class="row"
          >
            <dd 
              class="col-md-3 cols-2 w-auto"
            >
              絞り込み:
            </dd>
            <dd 
              class="cols-8 col-md-2 w-auto"
            >
              <div 
                v-for="(type,typeIndex) in selectType" 
                :key="typeIndex" 
                class="text-nowrap form-check-inline"
              >
                <input
                  v-model="search.selectType" 
                  class="form-check-input" 
                  type="checkbox"  
                  :id="type.value" 
                  :value="type.id"
                >
                <label 
                  class="form-check-label w-auto" 
                  :for="type.value"
                >
                  {{ type.text }}
                </label>
              </div>
            </dd>
          </dl>
        </b-col>
        <b-col 
          md="auto"
          cols="4"
        >
          <b-button 
            variant="success"
            @click="searchAreaList"
          >
            絞り込み
          </b-button>
        </b-col>
      </b-row>
      <b-row 
        class=" mt-1 info-area"
      >
        <b-col 
          md="3"
          cols="4"
        >
        </b-col>

        <b-col 
          class="text-center "
          md="5"
          cols="10"
        >
          <b>
            表示件数：{{ totalAreaList }}件中{{ displayAreaList }}件
          </b>
          <paginate
            :page-count="getPaginateCount"
            :prev-text="'<'"
            :next-text="'>'"
            :click-handler="paginateClickCallback"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            :first-last-button="true"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
            v-model="currentPage"
          />
        </b-col>
        <b-col
          md="3"
          lg="3"
          cols="5"
          class="w-auto"
        >
          <select 
            class="form-select " 
            name="displayLimits" 
            v-model="display"
          >
            <option 
              v-for="displayLimit in displayLimits" 
              :key="'displayLimit-' + displayLimit.id"
              :value="displayLimit.value"
            >
              {{ displayLimit.text }}
            </option>
          </select>
        </b-col>
        <b-col
          md="2"
          lg="2"
          cols="5"
        >
          <b-button 
            variant="primary"
            @click="changeLimit"
          >
            表示件数変更
          </b-button>
        </b-col>
      </b-row>
      <span 
        class="text-danger" 
        v-if="message.search !== ''"
      >
        {{ message.search }}
      </span>
      <b-overlay
        :show="showLoader"
      >
        <b-table-simple 
          bordered
          class="table table_sticky"
          style="font-size:14px;max-width:100%; "
          responsive
        >
          <b-thead
            id="tableTop"
            class="table-secondary"
            head-variant="secondary"
          >
            <b-tr>
              <b-th
                scope="col"
                style="width:1%;"
              >
                <input 
                  name="allCheck"  
                  class="form-check-input" 
                  type="checkbox"
                  @click="selectAllCheckArea"
                  :checked="isAreaAllSelect"
                >
              </b-th>
              <b-th
                scope="col"
                style="width:6%;"
                class="text-center"
              >
                外観対象エリア
              </b-th>
              <b-th
                scope="col"
                style="width:6%;"
                class="text-center"
              >
                内観対象エリア
              </b-th>
              <b-th
                scope="col"
                style="width:10%;"
                class="text-center"
              >
                県
              </b-th>
              <b-th
                scope="col"
                style="width:10%;"
                class="text-center"
              >
                市区郡
              </b-th>
              <b-th
                scope="col"
                style="width:10%;"
                class="text-center"
              >
                外観担当者
              </b-th>
              <b-th
                scope="col"
                style="width:10%;"
                class="text-center"
              >
                内観担当者
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody
          >
            <b-tr
              v-for="(areaList,areaIndex) in areaLists"
              :key="areaIndex"
            >
              <b-td>
                <b>
                  {{ areaIndex + 1 }}
                </b>
                <br>
                <input 
                  class="form-check-input" 
                  type="checkbox"
                  :value="{
                      prefectureCode:areaList.cPrefectureCd,
                      cityCode:areaList.cCityCd
                    }"
                  v-model="checkUpdate"
                >
              </b-td>
              <b-td
                class="text-center table_link"
                @click="showSettingStatusModal(
                  areaList.cPrefectureCd,
                  areaList.cCityCd,
                  'exteriorArea'
                )"
              >
                <span 
                  v-if="areaList.boExteriorFlag == 0"
                >
                  OFF
                </span>
                <span 
                  v-else-if="areaList.boExteriorFlag == 1"
                >
                  ON
                </span>
              </b-td>
              <b-td
                class="text-center table_link"
                @click="showSettingStatusModal(
                  areaList.cPrefectureCd,
                  areaList.cCityCd,
                  'introArea'
                )"
              >
                <span 
                  v-if="areaList.boIntrospectionFlag == 0"
                >
                  OFF
                </span>
                <span 
                  v-else-if="areaList.boIntrospectionFlag == 1"
                >
                  ON
                </span>
              </b-td>
              <b-td
                class="text-center"
              >
                {{ areaList.vcPrefecturesName }}
              </b-td>
              <b-td
                class="text-center"
              >
                {{ areaList.vcCityName }}
              </b-td>
              <b-td 
                class="text-center table_link"
                @click="showUserModal(
                  areaList.cPrefectureCd,
                  areaList.cCityCd,
                  'exteriorresPonsible'
                )"
              >
                {{ areaList.exterUName }}
              </b-td>
              <b-td 
                class="text-center table_link"
                @click="showUserModal(
                  areaList.cPrefectureCd,
                  areaList.cCityCd,
                  'introPonsible'
                )"
              >
                {{ areaList.introUName }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </b-container>
    <UpdateUserModal
      :userID="userID"
      :checkUpdate="checkUpdate"
      :areaUpdateType="selectUpdateType"
      :updatePageType="updatePageType"
      :prefectureCode="selectPrefectureCode"
      :cityCode="selectCityCode"
    />
    <UpdateSettingStatusModal
      :userID="userID"
      :checkUpdate="checkUpdate"
      :areaUpdateType="selectUpdateType"
      :prefectureCode="selectPrefectureCode"
      :cityCode="selectCityCode"
    />
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import UpdateUserModal from '../../components/UpdateUserModal.vue'
import UpdateSettingStatusModal from '../../components/UpdateSettingStatusModal.vue'
import { Apimixin } from '../../mixins/api'

export default {
  title: '撮影担当エリア編集',
  components: {
    Header,
    UpdateUserModal,
    UpdateSettingStatusModal
  },
  mixins:[Apimixin],
  data() {
    return {
      userID:'',
      prefectures:[
        {id:'01',value:'北海道'},
        {id:'02',value:'青森県'},
        {id:'03',value:'岩手県'},
        {id:'04',value:'宮城県'},
        {id:'05',value:'秋田県'},
        {id:'06',value:'山形県'},
        {id:'07',value:'福島県'},
        {id:'08',value:'茨城県'},
        {id:'09',value:'栃木県'},
        {id:'10',value:'群馬県'},
        {id:'11',value:'埼玉県'},
        {id:'12',value:'千葉県'},
        {id:'13',value:'東京都'},
        {id:'14',value:'神奈川県'},
        {id:'15',value:'新潟県'},
        {id:'16',value:'富山県'},
        {id:'17',value:'石川県'},
        {id:'18',value:'福井県'},
        {id:'19',value:'山梨県'},
        {id:'20',value:'長野県'},
        {id:'21',value:'岐阜県'},
        {id:'22',value:'静岡県'},
        {id:'23',value:'愛知県'},
        {id:'24',value:'三重県'},
        {id:'25',value:'滋賀県'},
        {id:'26',value:'京都府'},
        {id:'27',value:'大阪府'},
        {id:'28',value:'兵庫県'},
        {id:'29',value:'奈良県'},
        {id:'30',value:'和歌山県'},
        {id:'31',value:'鳥取県'},
        {id:'32',value:'島根県'},
        {id:'33',value:'岡山県'},
        {id:'34',value:'広島県'},
        {id:'35',value:'山口県'},
        {id:'36',value:'徳島県'},
        {id:'37',value:'香川県'},
        {id:'38',value:'愛媛県'},
        {id:'39',value:'高知県'},
        {id:'40',value:'福岡県'},
        {id:'41',value:'佐賀県'},
        {id:'42',value:'長崎県'},
        {id:'43',value:'熊本県'},
        {id:'44',value:'大分県'},
        {id:'45',value:'宮崎県'},
        {id:'46',value:'鹿児島県'},
        {id:'47',value:'沖縄県'},
        
      ],
      selectType:[
        {id:1,text:'外観',value:'exterior'},
        {id:2,text:'内観',value:'intro'},
      ],
      settingStatus:[
        {id:1,value:'ON'},
        {id:0,value:'OFF'}
      ],
      search:
      {
        user:'',
        settingStatus:'all',
        address:'',
        selectType:[],
        sort:'',
        sortType:''
      },
      limit:200,
      currentPage:1,
      display:'',
      isAreaAllSelect:false,
      checkUpdate:[],
      selectEstateID:'',
      selectShootingProgressID:'',
      selectShootingType:'',
      areaLists:[],
      totalAreaList:'',
      displayAreaList:'',
      selectPrefectureCode:'',
      selectCityCode:'',
      selectUpdateType:'',
      updatePageType:'',
      message:{
        search:''
      }

    }
  },
  created() {
    this.userID = sessionStorage.getItem('userId')
    //ユーザーデータの取得
    this.getUsers(this.userID)
  },
  mounted() {
    this.showLoader = true
    //limit値がセッションストレージにあれば取得
    if(sessionStorage.getItem('areaListLimit') != null) {
      this.limit = Number(sessionStorage.getItem('areaListLimit'))
    }
    //currentPage値がセッションストレージにあれば取得
    if(sessionStorage.getItem('areaListCurrentPage') != null) {
      this.currentPage = Number(sessionStorage.getItem('areaListCurrentPage'))
    }
    //検索履歴がセッションストレージにあれば取得
    if(sessionStorage.getItem('areaListSearch') != null) {
      this.searchOption = true
      this.search = JSON.parse(sessionStorage.getItem('areaListSearch'))
    }
    this.display = this.limit
    this.getLists()
  },
  methods:{
    //絞り込み処理
    searchAreaList() {
      this.areaLists = []
      this.showLoader = true
      this.searchOption = true
      sessionStorage.setItem('areaListSearch',JSON.stringify(this.search))
      this.currentPage = 1
      this.getLists();
    },
    //チェックボックス一括チェック
    selectAllCheckArea() {
      if(this.isAreaAllSelect) {
        this.checkUpdate = []
        this.isAreaAllSelect = false
      } else {
        this.checkUpdate = []
        for(let areaList of this.areaLists) {
          this.checkUpdate.push({
            prefectureCode:areaList.cPrefectureCd,
            cityCode:areaList.cCityCd
          })
        }
        this.isAreaAllSelect = true
      }
    },
    //担当者更新のモーダルオープン
    showUserModal(prefectureCode,cityCode,updateType) {
      this.selectPrefectureCode = prefectureCode
      this.selectCityCode = cityCode
      this.selectUpdateType = updateType
      this.updatePageType = 'area'
      this.$bvModal.show('UpdateUserModal')
    },
    //エリア設定状況更新モーダルオープン
    showSettingStatusModal(prefectureCode,cityCode,updateType) {
      this.selectPrefectureCode = prefectureCode
      this.selectCityCode = cityCode
      this.selectUpdateType = updateType
      this.$bvModal.show('UpdateSettingStatusModal')
    },
    //ページネーションクリック時現在のページ番号取得
    paginateClickCallback(pageNum) {
      this.currentPage = Number(pageNum);
      sessionStorage.setItem('areaListCurrentPage', JSON.stringify(this.currentPage))
      this.showLoader = true
      this.areaLists = []
      this.getLists();
    },
    //表示件数変更
    changeLimit() {
      this.limit = this.display
      this.showLoader = true
      this.areaLists = []
      sessionStorage.setItem('areaListLimit', JSON.stringify(this.limit))
      this.currentPage = 1
      this.getLists();
    },
    getLists() {
      let offset = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('areaListOffset', offset)
      sessionStorage.setItem('areaListLimit', this.limit)
      if(this.searchOption) {
        return this.getAreaList(offset,this.limit,this.search)
      } else {
        return this.getAreaList(offset, this.limit);
      }
    },
  },
  computed: {
    //総ページ数の計算
    getPaginateCount() {
      return Math.ceil(this.totalAreaList / this.limit);
    }
  },
  watch: {
    //チェックボックス全チェック
    'checkUpdate':function() {
      if (this.checkUpdate.length !== this.areaLists.length) {
        this.isAreaAllSelect = false
      } else {
        this.isAreaAllSelect = true
      }
    },
    'currentPage':function() {
      sessionStorage.setItem('areaListCurrentPage', this.currentPage)
    }
  }
}
</script>

<style scoped>
.table_link {
  cursor: pointer;
}
.table_link:hover {
  background-color:gainsboro;
}
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(65vh);
    
}
.table_sticky thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>